$color-green: #7cab7c;
$primary-color: $color-green;
$primary-color-accent: #d1e7dd;

$main-content-padding-top: 2rem;

/*#region page cards */

$page-card-border-radius: 0;
$page-card-bg-color: $primary-color;
$page-card-bg-color-hover: $primary-color-accent;
$page-card-font-color: #fff;
$page-card-text-align: center;
$page-card-overlay-font-color: $page-card-font-color;


/*#endregion */


/*#region share widget settings */

$sharing-widget-align: right;
$sharing-widget-title-text-transform: uppercase;
$sharing-widget-title-display: block;
$sharing-widget-title-font-weight: normal;
$sharing-widget-title-margin: 0;
$sharing-widget-facebook-colour: #3b5998;
$sharing-widget-twitter-colour: #1da1f2;
$sharing-widget-linkedin-colour: #0077b5;
$sharing-widget-googleplus-colour: #dd5044;
$sharing-widget-pinterest-colour: #bd081c;
$sharing-widget-email-colour: #9e005d;

/*#endregion */

/*#region cookie banner */

$cookie-banner-text-color: #fff;
$cookie-banner-bg-color: #212121;
$cookie-banner-border: none;
$cookie-banner-border-top: 4px solid $primary-color;
/*# endregion */


/*#region cookie banner */

$carousel-image-height: 600px;

/* #endregion */


/*#region links list */

$link-list-content-border-color: #ccc;
$link-list-content-border-style: dotted;
$link-list-content-border-width: 1px;
$link-list-content-font-color: #fff;
$link-list-content-bg-color: #616161;
$link-list-content-bg-color-hover: #343834;
$link-list-content-font-color-hover: $primary-color;

/* #endregion */

/*#region section blocks */

$section-block-primary-bg-color: $primary-color;
$section-block-primary-font-color: #fff;

$section-block-secondary-bg-color: $primary-color-accent;
$section-block-secondary-font-color: #fff;
$section-block-secondary-link-color: $primary-color;

/* #endregion */


/*#region form GDPR area */

$form-gdpr-block-bg-color: #f7f7f7;
$form-gdpr-block-border: 1px solid #ccc;
$form-gdpr-block-padding: 0.5rem;
$form-gdpr-block-margin: 0 0 1rem;
$form-gdpr-block-header-size: 100%;

/* #endregion */

/*#region TABS */

$tab-button-color: #d1e7dd;
$tab-button-text-color: inherit;
$tab-button-color-inactive: #fff;
$tab-button-text-color-inactive: #666;
$tab-button-border-inactive: 1px solid #ccc;
$tab-button-border-radius: 0;
$tab-button-vertical-border-color: $primary-color;
$tab-button-vertical-border-width: 2px;

/* #endregion */
