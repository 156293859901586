/// <reference path="_variables.scss" />

.main-content {
    .grid-component-link-list {
        .list-group {
            .list-group-item {
                background-color: $link-list-content-bg-color;
                color: $link-list-content-font-color;
                border: $link-list-content-border-width $link-list-content-border-style $link-list-content-border-color;

                &:hover {
                    background-color: $link-list-content-bg-color-hover;
                    color: $link-list-content-font-color-hover;
                }
            }
        }
    }
}
