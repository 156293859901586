@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css');
@import "bootstrap/scss/bootstrap";
@import 'components/_variables.scss';
@import 'components/_tabs.scss';
@import 'components/_carousel.scss';
@import 'components/_cookies.scss';
@import 'components/_forms.scss';
@import 'components/_page-cards.scss';
@import 'components/_sharing-widget.scss';
@import 'components/_link-list.scss';

/*https://www.vistaprint.co.uk/promotional-products/drinkware/sport-water-bottles/avenue-tm-norse-copper-vacuum-insulated-bottle-with-cork*/

body {
    font-family: 'Poppins', sans-serif;
    color: #666;
    font-weight: 300;
    font-size: 18px;
}

header {
    background-color: $color-green;
    color: #fff;
    padding: 1rem;

    h1 {
        font-weight: 200;
        color: #fff;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}

.main-content {
    padding: $main-content-padding-top 0 2rem;
}
@media only screen and (min-width: 1024px) {
    .navbar-brand{
        display:none;
    }
}
.pull-up {
    margin-top: -$main-content-padding-top;
}

footer {
    color: #fff;
    min-height: 300px;

    .footer-pre {
        background-color: $color-green;
        padding: 15px 0;

        .grid-component-social-channels {
            &--title {
                text-align: center;
            }

            ul {
                list-style: none;
                padding-left: 0;
                display: flex;
                justify-content: center;

                li {
                    font-size: 1rem;
                    margin-right: .5rem;

                    a {
                        color: #fff;
                        text-decoration: none;
                        transition: color .2s ease;

                        &:hover {
                            color: #212121;
                        }
                    }
                }
            }
        }
    }

    .footer-post {
        background: #212121;
        padding-top: 20px;
        padding-bottom: 20px;

        ul {
            list-style: none;
            padding-left: 0;

            li {
                a {
                    color: #fff;
                    text-decoration: none;
                    transition: color 1s ease;

                    &:hover {
                        color: $color-green;
                    }
                }
            }
        }
    }
}

.main-content {
    padding-top: 2rem;
    padding-bottom: 3rem;

    .grid-area {
        .container, .container-lg, .container-fluid {
            padding-top: 2rem;
        }
    }
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #000;
}

.btn {
    border-radius: 0;
}

.btn-primary {
    background-color: $color-green;
    border-color: $color-green;
}

.d-block-sm {
    width: 100%;
}

:root {
    --bs-border-radius: 0;
}

@media only screen and (min-width : 1024px) {
    .d-block-sm {
        width: unset;
    }
}

.alert {
    p, h2, h3 {
        margin-bottom: 0;
    }

    &-danger {
        p, h2, h3 {
            color: var(--bs-danger-text-emphasis);
        }
    }
}

.field-validation-valid {
    display: none;
}

.honey-pot,
.form-hp-area {
    input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }

    label {
        display: none;
    }
}

table {
    th {
        background-color: $primary-color;
    }
}

.articles {
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.page-link.active, .active > .page-link {
    background-color: $primary-color;
    border-color: $primary-color;
}

.page-item:not(.active) {
    .page-link {
        &:hover {
            background-color: $primary-color-accent;
            border-color: $primary-color-accent;
        }
    }
}

.article-content {
    img {
        max-width: 100%;

        @media only screen and (max-width: 600px) {
            height: auto;
            margin-bottom: 1rem;
        }
    }

    .article-category-link {
        background-color: #F6F6F6;
        border: 1px solid #dedede;
        padding: .5rem;
        color: #000;
        margin-right: .5rem;

        &:hover {
            text-decoration: none;
            background-color: #dedede;
        }
    }
}
